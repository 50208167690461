(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-marketplace-balance/assets/javascripts/marketplace-balance.js') >= 0) return;  svs.modules.push('/components/payment/middleware-marketplace-balance/assets/javascripts/marketplace-balance.js');
'use strict';

const {
  getAccountBalance
} = svs.components.marketplaceShared.getBalance;
const {
  InteractionContext
} = svs.components.payment.common.context;
const {
  MiddlewarePaymentError
} = svs.components.payment.common.error;
const {
  common
} = svs.components.payment;
const {
  recountRetailBalance
} = svs.components.marketplace.retailCommissionCounter;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const {
  Currency: currency
} = svs.utils.format;
const marketplaceBalance = onInteraction => _ref => {
  let {
    payment
  } = _ref;
  return async next => {
    const payableItems = payment.getPayableItems();
    const marketplaceWager = payableItems.find(payable => payable.activatedDrawId);
    if (!marketplaceWager) {
      return next(new MiddlewarePaymentError({
        clientCode: MiddlewarePaymentError.ErrorCodes.NOT_WAGER
      }));
    }
    const activatedDrawId = marketplaceWager.activatedDrawId;
    const productId = marketplaceWager.bets[0].productId;
    const drawNumber = marketplaceWager.bets[0].drawNumber;
    const role = marketplaceWager.role;
    const activatedDraw = await getAccountBalance.getBalance(role, productId, drawNumber, activatedDrawId, false);
    let balance;
    if (role === groupTypes.RETAILER) {
      var _activatedDraw$data, _activatedDraw$data2;
      const commissionPercent = marketplaceWager.commissionPercent;
      balance = recountRetailBalance(activatedDraw === null || activatedDraw === void 0 || (_activatedDraw$data = activatedDraw.data) === null || _activatedDraw$data === void 0 ? void 0 : _activatedDraw$data.soldShares, activatedDraw === null || activatedDraw === void 0 || (_activatedDraw$data2 = activatedDraw.data) === null || _activatedDraw$data2 === void 0 ? void 0 : _activatedDraw$data2.sharePrice, commissionPercent);
    } else {
      var _parseInt, _activatedDraw$data3;
      balance = (_parseInt = parseInt(activatedDraw === null || activatedDraw === void 0 || (_activatedDraw$data3 = activatedDraw.data) === null || _activatedDraw$data3 === void 0 ? void 0 : _activatedDraw$data3.balance, 10)) !== null && _parseInt !== void 0 ? _parseInt : undefined;
    }
    const price = payment.getTotalPrice();
    common.logger.info("Checking marketplace activatedDraw balance: ".concat(balance, ", price: ").concat(price));
    if (price > balance) {
      const wagerCountText = payment.count() > 1 ? 'de här spelen' : 'det här spelet';
      const title = "Ni har inte tillr\xE4ckligt med pengar f\xF6r att betala ".concat(wagerCountText);
      const messages = ["Att betala f\xF6r: ".concat(currency(balance), " kr"), "Pris: ".concat(currency(price), " kr")];
      const data = {
        balance,
        price,
        wagerCount: payment.count()
      };
      common.logger.info('Showing dialog for not enough money MP.');
      await onInteraction(new InteractionContext(InteractionContext.Severity.ERROR, InteractionContext.Code.NOT_ENOUGH_MONEY, data, title, messages));
      const mwError = new MiddlewarePaymentError({
        clientCode: MiddlewarePaymentError.ErrorCodes.NOT_ENOUGH_MONEY,
        extraData: {
          balance,
          price
        }
      });
      return next(mwError);
    }
    next();
  };
};
setGlobal('svs.components.payment.middleware.marketplaceBalance', marketplaceBalance);

 })(window);